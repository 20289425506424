/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const DonationForAtlantaHeartfulnessCenterUsa = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Atlanta Heart Notes Sponsorship, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("atlanta_heart_notes_sponsorship,_USA", event),
    []
  );

  return (
    <LayoutHome seoTitle="Donation for Atlanta Heartfulness Center, USA - Donations">
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box style={{ color: "#2d3550" }}>
          <div style={{ textAlign: "center" }}>
            <h1
              style={{
                color: "#2d3550",
                marginTop: "40px",
              }}
            >
              Donation for Atlanta Heartfulness Center, USA
            </h1>
          </div>
        </Box>
        <PageContent
          eventCallback={eventCallback}
          pageContent={pageContent(17, "Donate Now")}
        />
      </Grid>
    </LayoutHome>
  );
};

export default DonationForAtlantaHeartfulnessCenterUsa;
